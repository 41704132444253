import { Box, useTheme } from "@mui/system";
import React from "react";
import { Paper, Typography } from "@mui/material";
import { Alert } from "@mui/lab";

const SomethingWentWrong = ({error, errorInfo}) => {
    const theme = useTheme();
    return <Box display='flex' flexDirection='column' sx={{p: 2, backgroundColor: theme.palette.primary.main, height: '100vh'}}
                alignItems='center' justifyContent='center'>
        <Typography variant='h3' component='h1' sx={{color: 'white'}}>Something went wrong</Typography>
        <Alert severity='error'  sx={{my: 2, width: '100%'}} >
            {error.message}
        </Alert>
        <Paper>
            <pre style={{padding: '16px', overflowWrap: 'break-word', whiteSpace: 'break-spaces'}}>
                {JSON.stringify(errorInfo)}
            </pre>
        </Paper>
    </Box>
}

export default SomethingWentWrong;
