import React, { Suspense, useEffect } from 'react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { I18nextProvider } from 'react-i18next';
import i18n from '../../i18n/i18n';
import LoadingScreen from '../Utils/LoadingScreen';
import moment from 'moment';
import 'moment/locale/de';
import { useCookies } from 'react-cookie'
import { StyledEngineProvider } from '@mui/material/styles';
import { Route, Routes } from "react-router";
import Login from "../Login/Login";
import { deDE as coreDeDE, enUS as coreEnUS } from '@mui/material/locale';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterMoment from '@mui/lab/AdapterMoment';
import ErrorBoundary from "./ErrorBoundary";
import { BrowserRouter, Navigate } from "react-router-dom";
import LoginCallback from "../Login/LoginCallback";
import DropboxCallback from "../Redirect/DropboxCallback";
import TelegramCallback from "../Redirect/TelegramCallback";

const createCustomTheme = (locale, theme) => {
    return createTheme(
        {
            palette: {
                mode: theme === 'dark' ? 'dark' : 'light',
                background: {
                    default: theme === 'dark' ? '#121212' : '#f3f3f3'
                    //default: "#f5f5f5"
                },
                primary: {
                    main: '#3b7ea9',
                },
                secondary: {
                    main: '#ff7400',
                },
                action: {
                    selected: 'rgba(0,0,0,0.12)',
                    selectedOpacity: 0.12
                }
            }
        },
        locale === 'de' ? coreDeDE : coreEnUS,
    )
};

const defaultTheme = createCustomTheme('de', 'light');

const App = (props) => {

    const [cookies, setCookie] = useCookies(['locale', 'theme']);
    const {locale} = cookies
    useEffect(() => {
        let myLocale = locale
        if (!locale) {
            myLocale = 'de'
            setCookie('locale', myLocale)
        }
        i18n.changeLanguage(myLocale);
        moment.locale(myLocale);
    }, [locale, setCookie])

    return <ThemeProvider theme={defaultTheme}>
        <StyledEngineProvider injectFirst>
            <CssBaseline/>
            <ErrorBoundary>
                <Suspense fallback={<LoadingScreen loading={true}/>}>
                    <I18nextProvider i18n={i18n}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <BrowserRouter>
                                <Routes>
                                    <Route path='login' element={<Login/>}/>
                                    <Route path='login-callback' element={<LoginCallback/>}/>
                                    <Route path='telegram-callback' element={<TelegramCallback/>}/>
                                    <Route path='dropbox-callback' element={<DropboxCallback/>}/>
                                    <Route path='*' element={<Navigate to='/login' replace/>}></Route>
                                </Routes>
                            </BrowserRouter>
                        </LocalizationProvider>
                    </I18nextProvider>
                </Suspense>
            </ErrorBoundary>
        </StyledEngineProvider>
    </ThemeProvider>
};


export default App;
