import React, { useEffect } from 'react';
import * as qs from 'query-string';
import { Grid, LinearProgress } from "@mui/material";
import Logo from "../../images/logo.png";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router";
import Api from "../../core/Api";
import { redirectToSystem } from "../../core/helper";

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
    backgroundColor: theme.palette.primary.dark,
    height: 6
}))

const CompanyLogo = styled('img')(({theme}) => ({
    marginBottom: theme.spacing(2),
    maxWidth: '600px'
}))


const LoginCallback = (props) => {

    const [loading, setLoading] = React.useState(true);
    const location = useLocation();
    useEffect(() => {
        setLoading(true);
        const query = qs.parse(location.hash);
        if (!query.id_token) {
            setLoading(false);
            return;
        }
        Api.fetch({
            endpoint: 'user/external-login',
            method: "POST",
            auth: false,
            body: {
                idToken: query.id_token,
            },
        }).then((res) => {
            console.log(res)
            redirectToSystem(res.response.redirectDomain, res.response.token.idToken, res.response.token.accessToken, res.response.token.refreshToken)
        }, (err) => {
        }).then(() => {
            setLoading(false);
        });

    }, [location.hash])

    return <Grid container justifyContent='center' alignItems='center' sx={{
        height: '100%'
    }}>
        <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
            <CompanyLogo alt='company logo' src={Logo}/>
            {loading && <BorderLinearProgress color='primary'/>}
        </Grid>
    </Grid>
}

export default LoginCallback

