import React, { useEffect } from 'react';
import * as qs from 'query-string';
import { Grid, LinearProgress } from "@mui/material";
import Logo from "../../images/logo.png";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router";

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
    backgroundColor: theme.palette.primary.dark,
    height: 6
}))

const CompanyLogo = styled('img')(({theme}) => ({
    marginBottom: theme.spacing(2),
    maxWidth: '600px'
}))


const DropboxCallback = (props) => {

    const [loading, setLoading] = React.useState(true);
    const location = useLocation();
    useEffect(() => {
        setLoading(true);
        const query = qs.parse(location.search);
        if (!query.code && !query.state) {
            setLoading(false);
            return;
        }

        const state = JSON.parse(window.atob(query.state));

        if(!state.redirect) {
            setLoading(false);
            return;
        }

        window.location = state.redirect + '?code=' + query.code;
    }, [location.search])

    return <Grid container justifyContent='center' alignItems='center' sx={{
        height: '100%'
    }}>
        <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
            <CompanyLogo alt='company logo' src={Logo}/>
            {loading && <BorderLinearProgress color='primary'/>}
        </Grid>
    </Grid>
}

export default DropboxCallback

