import React, { useEffect } from 'react';
import * as qs from 'query-string';
import { Button, Grid } from "@mui/material";
import { useLocation } from "react-router";
import TelegramLoginButton from 'react-telegram-login';
import { useTranslation } from "react-i18next";

const DropboxCallback = (props) => {
    const {t} = useTranslation();
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const location = useLocation();
    useEffect(() => {
        const query = qs.parse(location.search);
        if (!query.state) {
            return;
        }

        const state = JSON.parse(window.atob(query.state));

        if (!state.redirect) {
            return;
        }

        setRedirectUrl(state.redirect);
    }, [location.search])

    return <Grid container justifyContent='center' alignItems='center' sx={{
        height: '100%'
    }}>
        <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
            {Boolean(redirectUrl) && <TelegramLoginButton dataAuthUrl={redirectUrl + '&success=true'} botName="VeCoDeskBot"/>}
            {Boolean(redirectUrl) && <Button href={redirectUrl + '&success=false'}
                                             sx={{mt: 1}}
                                             color='primary'>{t('telegram.back')}</Button>}
        </Grid>
    </Grid>
}

export default DropboxCallback

